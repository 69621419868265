<template>
  <div class="w-full h-full flex flex-col relative">
    <div class="absolute right-3 top-3 w-52 max-h-56 overflow-hidden bg-white z-9999 rounded-xl flex flex-col border border-theme-700 shadow-xl">
      <div class="bg-theme-500 w-full rounded-t-xl p-2 flex flex-col sticky top-0">
        <div class="text-white text-xl font-bold">
          {{ deSnakeCase(search.datasetDescription.mapSupport.groupItemField) }}s <template v-if="!loadingData">({{ filteredItems.length.toLocaleString() }})</template>
        </div>
        <div class="flex text-xs">
          <div class="input-group-text w-7"><i class="fas fa-filter" /></div>
          <input class="form-control group-text" type="text" :placeholder="`Filter ${deSnakeCase(search.datasetDescription.mapSupport.groupItemField)}s`" v-model="itemFilter" />
        </div>
      </div>
      <div class="h-full overflow-auto flex flex-col items-center justify-center">
        <div v-for="(itemKey, idx) in filteredItems" :key="itemKey" :class="['px-2 w-full', { 'bg-white': idx % 2 === 0 }, { 'bg-theme-100': idx % 2 !== 0 }]">
          <map-item
            :map="map"
            :search-id="searchId"
            :name="itemKey"
            :item="items[itemKey]"
            :scrub-time-ms="scrubTimeMs">
          </map-item>
        </div>
        <div v-if="filteredItems.length === 0 && !loadingData">No {{ deSnakeCase(search.datasetDescription.mapSupport.groupItemField) }}s found</div>
      </div>
      <loading v-if="loadingData" class="text-sm border-t border-theme-500" :text="`Loading ${deSnakeCase(search.datasetDescription.mapSupport.groupItemField)}s...`"></loading>
      <retryable-error v-else-if="errorLoading" class="text-sm border-t border-theme-500" text="Error loading data" @retry="fetchLastResults"></retryable-error>
    </div>
    <map-component @map-init="onMapInit" :allow-fullscreen="false"></map-component>
    <div v-if="search.filters.timeRange !== null && endTimeMs" class="sticky w-full max-w-full bottom-0 z-999 h-14 max-h-14 min-h-14">
      <playback-scrubber
        v-model="endTimeMs"
        :start-time-ms="search.filters.timeRange.start.utc().valueOf()"
        :end-time-ms="search.filters.timeRange.end.utc().valueOf()"
        :disabled="loadingData"
        @scrub="onScrub">
      </playback-scrubber>
    </div>
  </div>
</template>

<script>
import { shallowRef } from 'vue'
import _ from 'lodash'
import moment from 'moment'
import DisplayFormatMixin from '@/mixins/DisplayFormatMixin'
import Loading from '@/components/Loading'
import MapComponent from '@/components/MapComponent'
import MapItem from '@/components/map/MapItem'
import PlaybackScrubber from '@/components/map/PlaybackScrubber'
import RetryableError from '@/components/RetryableError'
import SearchQueryMixin from '@/mixins/SearchQueryMixin'

export default {
  name: 'map-search-results',
  components: {
    Loading,
    MapComponent,
    MapItem,
    PlaybackScrubber,
    RetryableError
  },
  mixins: [
    DisplayFormatMixin,
    SearchQueryMixin
  ],
  data () {
    return {
      map: null,
      scrubTimeMs: null
    }
  },
  computed: {
    endTimeMs: {
      get () {
        return this.mapResults?.endTimeMs ?? null
      },
      set (endTimeMs) {
        const searchId = this.searchId
        this.$store.commit('setSearchMapEndTimeMs', { searchId, endTimeMs })
        this.fetchLastResults()
      }
    },
    itemFilter: {
      get () {
        return this.mapResults?.itemFilter ?? ''
      },
      set (itemFilter) {
        const searchId = this.searchId
        this.$store.commit('setSearchMapItemFilter', { searchId, itemFilter })
      }
    },
    mapResults () {
      if (this.search === null) return null
      return this.search.mapResults
    },
    items () {
      return this.mapResults?.items ?? null
    },
    loadingData () {
      return this.mapResults?.loadingData ?? false
    },
    errorLoading () {
      return this.mapResults?.errorLoading ?? false
    },
    resultTitle () {
      const numberResults = this.items === null ? 0 : Object.keys(this.items).length
      const groupItemField = this.deSnakeCase(this.search.datasetDescription.mapSupport.groupItemField)
      return `${numberResults} ${groupItemField}${numberResults !== 1 ? 's' : ''}`
    },
    filteredItems () {
      if (typeof this.items === 'undefined' || this.items === null) return []
      const allItems = _.sortBy(Object.keys(this.items))
      if (this.itemFilter.length === 0) return allItems

      return allItems.filter(item => item.toLowerCase().includes(this.itemFilter.toLowerCase()))
    }
  },
  methods: {
    onScrub (scrubTimeMs) {
      this.$store.commit('clearMapItemCurrentResults', this.searchId)
      this.scrubTimeMs = scrubTimeMs
    },
    resetMapCenterZoom () {
      if (this.search.mapResults.mapCenter === null || this.map === null) return
      this.map.setView(this.search.mapResults.mapCenter, this.search.mapResults.mapZoom, { animate: false })
    },
    setInitialEndTime () {
      const searchId = this.searchId
      if (this.search.filters.timeRange === null) {
        this.$store.commit('setSearchMapEndTimeMs', { searchId, endTimeMs: null })
      } else if (this.endTimeMs === null) {
        const endTimeMs = this.search.filters.timeRange.end.utc().valueOf()
        this.$store.commit('setSearchMapEndTimeMs', { searchId, endTimeMs })
      }
    },
    onMapInit (map) {
      this.map = shallowRef(map)
      this.resetMapCenterZoom()

      const self = this
      map.on('moveend zoomend', () => {
        self.search.mapResults.mapCenter = map.getCenter()
        self.search.mapResults.mapZoom = map.getZoom()
      })
    },
    async fetchLastResults () {
      if (this.search.mapResults.loadingData === true) return
      const searchId = this.searchId
      this.$store.commit('setSearchMapErrorLoading', { searchId, errorLoading: false })
      const groupItemField = this.search.datasetDescription.mapSupport.groupItemField
      const itemLocationField = this.search.datasetDescription.mapSupport.itemLocationField
      const locationFields = _.find(this.search.datasetDescription.schema, ['name', itemLocationField ?? '-'])
      if (typeof locationFields === 'undefined' || groupItemField === null) {
        this.$swal({
          icon: 'error',
          title: 'Error Loading Map',
          text: 'This set of Search Results cannot be displayed on a map. You may view these rsults in Raw or Table mode.',
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        console.error(`Unable to find column ${itemLocationField} in schema, can't plot map`)
        this.$store.commit('setSearchMapErrorLoading', { searchId, errorLoading: true })
        return
      }

      this.$store.commit('setSearchMapLoading', { searchId, loadingData: true })
      const searchData = JSON.parse(JSON.stringify(this.searchPayload))
      searchData.paging = null
      if (searchData.timeRange !== null && this.endTimeMs !== null) {
        // for temporal searches where we have an end time (from the scrubber), update the search payload with the scrubbed end time
        searchData.timeRange.end = moment.utc(this.endTimeMs).milliseconds(0)
      }
      const dispatchData = { category: this.search.datasetDescription.category, datasetId: this.search.datasetDescription.datasetId, searchData }

      try {
        const items = {}
        const response = await this.$store.dispatch('fetchLastResults', dispatchData)
        for (const result of response.data.results) {
          items[result[groupItemField]] = {
            mapMarker: null,
            currentResult: result,
            path: {
              points: null,
              layer: null,
              isLoading: false,
              isVisible: false,
              loadingPercent: 0
            }
          }
        }

        // if we have never set items, set all; otherwise just update current result
        if (this.items === null) {
          this.$store.commit('setSearchMapItems', { searchId, items })
        } else {
          const currentResults = {}
          for (const key of Object.keys(items)) {
            currentResults[key] = items[key].currentResult
          }
          this.$store.commit('setSearchMapItemsCurrentResults', { searchId, currentResults })
        }
      } catch (error) {
        console.error('Error loading last results', error)
        this.$store.commit('setSearchMapItems', { searchId, items: {} })
        this.$store.commit('setSearchMapErrorLoading', { searchId, errorLoading: true })
      } finally {
        this.$store.commit('setSearchMapLoading', { searchId, loadingData: false })
      }
    }
  },
  mounted () {
    this.setInitialEndTime()

    if (this.items === null) {
      this.fetchLastResults()
    }
  },
  unmounted () {
    this.map = null
  }
}
</script>

<style lang="less" scoped>
:deep(.leaflet-container a.leaflet-popup-close-button) {
  @apply text-theme-100;
  &:hover {
    @apply text-theme-300;
  }
}

:deep(.leaflet-popup-content-wrapper) {
  @apply p-0;
}

:deep(.leaflet-popup-content) {
  margin: 0;
}

:deep(.leaflet-top), :deep(.leaflet-bottom) {
  z-index: 998;
}
</style>
