<template>
  <div v-if="search" class="flex flex-col h-screen">
    <div class="p-1 pb-2 gap-2 flex justify-between bg-theme-500 items-center text-white sticky top-0 z-999">
      <div class="flex gap-1">
        <inline-dropdown :options="actions" button-title="Actions"></inline-dropdown>
      </div>
      <div class="flex flex-col justify-center items-center gap-1">
        <div class="text-2xl font-bold">{{ generateTitle(search) }}</div>
        <div class="flex text-xs cursor-pointer border rounded font-normal">
          <template v-if="supportsMapView">
            <div :class="['flex gap-1 items-center py-1 px-2 rounded hover:bg-theme-200', {'bg-theme-900': isViewModeMap}]" @click="setViewModeMap"><i class="fas fa-code-map" />Map</div>
            <div class="border-r"></div>
          </template>
          <div :class="['flex gap-1 items-center py-1 px-2 rounded hover:bg-theme-200', {'bg-theme-900': isViewModeRaw}]" @click="setViewModeRaw"><i class="fas fa-code" />Raw</div>
          <div class="border-r"></div>
          <div :class="['flex gap-1 items-center py-1 px-2 rounded hover:bg-theme-200', {'bg-theme-900': isViewModeTable}]" @click="setViewModeTable"><i class="fas fa-table" />Table</div>
        </div>
      </div>
      <small>{{ generateSubtitle(search) }}</small>
    </div>

    <div class="flex flex-col bg-theme-100 sticky top-0 text-theme-500">
      <div v-if="showColumnEditor || hiddenColumnCount !== 0" :class="['justify-center items-center gap-2 flex text-sm py-1 font-bold cursor-pointer', {'border-b border-b-gray-300': showColumnEditor}]" @click="showColumnEditor = !showColumnEditor">
        <i class="fas fa-tabble" />{{ hiddenColumnCount }} Column{{ hiddenColumnCount === 1 ? '' : 's' }} Hidden<i :class="['fa', {'fa-chevron-down': !showColumnEditor}, {'fa-chevron-up': showColumnEditor}]" />
      </div>
      <div :class="['grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 transition-all ease-in-out duration-500', {'max-h-0 overflow-hidden': !showColumnEditor}, {'h-auto max-h-screen': showColumnEditor}]">
        <div v-for="col in columns" :key="col.name" class="form-control bg-transparent border-0">
          <input type="checkbox" :id="col.name" :value="col.name" @change="e => setColumnVisibility(e.target.value, e.target.checked)" :checked="search.visibleColumns === null || search.visibleColumns.includes(col.name)">
          <label :for="col.name" class="ml-1">{{ col.display }}</label>
        </div>
      </div>
    </div>
    <div v-if="search.isSample === false" class="flex flex-col bg-theme-700 text-white sticky top-0">
      <div :class="['justify-center items-center gap-2 flex text-sm py-1 font-bold cursor-pointer', {'border-b border-b-gray-300': showFilters}]" @click="showFilters = !showFilters">
        <i class="fas fa-filter" />{{ filtersUsed.length }} Filter{{ filtersUsed.length === 1 ? '' : 's' }} Applied<i :class="['fa', {'fa-chevron-down': !showFilters}, {'fa-chevron-up': showFilters}]" />
      </div>
      <div :class="['transition-all ease-in-out duration-500', {'max-h-0 overflow-hidden': !showFilters}, {'h-auto max-h-screen': showFilters}]">
        <div v-for="filter in filtersUsed" :key="filter.name" :class="['flex justify-between items-center py-1', { 'border-b border-b-gray-300': additionalAvailableFilters.length > 0 }]">
          <div class="flex items-center gap-2 mx-2">
            <i :class="`fas ${filter.icon}`" />
            <div class="flex flex-col">
              <div class="font-bold">{{ filter.name }}</div>
              <div>{{ filter.value }}</div>
            </div>
          </div>
          <div class="flex">
            <div v-if="filter.viewDetailsHandler !== null" class="btn btn-theme btn-sm mx-2" @click="filter.viewDetailsHandler">Details</div>
            <button v-if="filter.isEditable" class="btn btn-theme-muted btn-sm mx-2" @click="openFilterEditor(filter.idName, filter.type)" :disabled="search.isSearching">Modify</button>
          </div>
        </div>
        <div v-if="!isAddingFilter && additionalAvailableFilters.length > 0" class="w-full text-center p-1 border-b border-b-gray-300 cursor-pointer hover:bg-theme-500" @click="isAddingFilter = true"><i class="fas fa-plus mr-1" />Add Filter</div>
        <v-select v-if="isAddingFilter" :options="additionalAvailableFilters" @update:modelValue="onFilterAddChange" placeholder="select a filter" :get-option-label="filter => filter.display" :append-to-body="true" class="w-full"></v-select>
      </div>
    </div>

    <div v-if="search.results === null" class="flex h-full items-center justify-center">
      <loading v-if="search.isSearching" text="Searching..."></loading>
      <div v-else>Error Occurred</div>
    </div>
    <div v-else class="overflow-auto h-full">
      <div v-if="search.results.length === 0" class="flex h-full justify-center items-center text-theme-500">
        <div class="text-center">no results</div>
      </div>
      <div v-else-if="isViewModeRaw" class="bg-gray-200 p-2">
        <JsonViewer :json-string="JSON.stringify(search.results, null, 4)"></JsonViewer>
      </div>
      <div v-else-if="isViewModeMap" class="h-full">
        <map-search-results :search-id="searchId" :key="searchId"></map-search-results>
      </div>
      <table v-else class="w-full break-words">
        <thead>
          <tr class="sticky top-0 bg-theme-400">
            <template v-for="col in columns" :key="col.name">
              <th v-if="search.visibleColumns === null || search.visibleColumns.includes(col.name)" class="text-white px-4 max-w-md" :title="col.description">
                {{ col.display }}
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, resultIdx) in search.results" :key="item[`x${search.id}`]" :class="[{ 'bg-white': resultIdx % 2 === 0, 'bg-theme-100': resultIdx % 2 !== 0 }]">
            <template v-for="col in columns" :key="col.name">
              <td v-if="search.visibleColumns === null || search.visibleColumns.includes(col.name)" class="px-4 max-w-md text-center">
                <template v-if="col.combined !== null && getValue(item, col) !== '-' && detailedCombinedTypes.includes(col.combined.type)">
                  <span class="underline text-theme-400 cursor-pointer hover:text-theme-200" @click="showDetails(item, col)">{{ getValue(item, col) }}</span>
                </template>
                <span v-else>
                  {{ getValue(item, col) }}
                </span>
                <div v-if="getValue(item, col, false).length > datasetCharacterLimit" class="underline text-theme-400 cursor-pointer hover:text-theme-200" @click="overflowValue = { item, column: col }">See More</div>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
      <template v-if="viewModeSupportsPaging">
        <loading v-if="search.isSearching" class="sticky left-0 w-full" text="Loading More Results..."></loading>
        <button v-if="!search.isSearching && !search.searchError && hasMoreResults" @click="performSearch" class="sticky left-0 btn btn-theme-muted w-full border-dashed mt-3 bg-transparent">Load More...</button>
        <div v-if="!search.isSearching && !search.searchError && !hasMoreResults" class="w-full text-center text-sm mt-3">no more results to load</div>
      </template>
    </div>

    <retryable-error v-if="search.searchError" class="bg-red-500 text-white" text="Error getting search results." @retry="performSearch"></retryable-error>

    <modal v-if="locationRow !== null">
      <template v-slot:header>
        <div class="flex justify-between w-full">
          <h1 v-if="titleColumn" class="text-2xl">{{ getValue(locationRow.item, titleColumn) }}</h1>
          <h3>{{ getValue(locationRow.item, locationRow.column) }}</h3>
        </div>
        <h3 v-if="subtitleColumn">{{ getValue(locationRow.item, subtitleColumn) }}</h3>
      </template>
      <template v-slot:body>
        <div class="w-full h-96">
          <h1 class="text-xl font-bold">{{ locationRow.column.display }}</h1>
          <map-component :location-pin-lat-lng="[locationRow.item[locationRow.column.combined.fields[0]], locationRow.item[locationRow.column.combined.fields[1]]]" :fa-map-icon="search.datasetDescription.faMapIcon"></map-component>
        </div>
      </template>
      <template v-slot:footer>
        <button class="btn btn-theme" @click="locationRow = null">Close</button>
      </template>
    </modal>

    <modal v-if="filesetRow !== null" large-width-class="lg:w-5/6">
      <template v-slot:header>
        <div class="flex w-full">
          <h1 class="text-2xl">{{ getValue(filesetRow.item, filesetRow.column, false )}}</h1>
        </div>
      </template>
      <template v-slot:body>
        <div class="w-full h-96 overflow-auto">
          <fileset-browser class="h-full" :locked-fileset-id="filesetRow.item[filesetRow.column.combined.fields[0]]" :locked-fileset-path="filesetRow.item[filesetRow.column.combined.fields[1]].split('/')" :is-height-constrained="true"></fileset-browser>
        </div>
      </template>
      <template v-slot:footer>
        <button class="btn btn-theme" @click="filesetRow = null">Close</button>
      </template>
    </modal>

    <modal v-if="overflowValue !== null" large-width-class="lg:w-5/6">
      <template v-slot:header>
        <div class="flex w-full">
          <h1 class="text-2xl">{{ overflowValue.column.display }}</h1>
        </div>
      </template>
      <template v-slot:body>
        <div class="w-full max-h-96 overflow-auto whitespace-pre-wrap break-all">
          {{ getValue(overflowValue.item, overflowValue.column, false) }}
        </div>
      </template>
      <template v-slot:footer>
        <button class="btn btn-theme" @click="overflowValue = null">Close</button>
      </template>
    </modal>

    <modal v-if="showExportModal">
      <template v-slot:header>
        <div class="flex w-full">
          <h1 class="text-2xl">Export Current Results</h1>
        </div>
      </template>
      <template v-slot:body>
        <div class="w-full flex flex-col">
          <div>You can export the {{ search.results.length.toLocaleString() }} result{{ search.results.length !== 1 ? 's' : '' }} that are currently displayed in the UI to either a CSV or JSON file.</div>
          <div class="flex w-full gap-2 justify-center my-2">
            <button class="btn btn-theme-muted" @click="exportResults('csv')"><i class="fas fa-download mr-1" />CSV</button>
            <button class="btn btn-theme" @click="exportResults('json')"><i class="fas fa-download mr-1" />JSON</button>
          </div>
          <div>If you need to download more results, then <span class="text-theme-500 hover:text-theme-300 underline cursor-pointer" @click="copyCurlCommand">click here to copy the cURL command.</span></div>
        </div>
      </template>
      <template v-slot:footer>
        <button class="btn btn-theme" @click="showExportModal = false">Close</button>
      </template>
    </modal>

    <modal v-if="showGeospatialFilter">
      <template v-slot:header>
        <h1 class="text-2xl">Geospatial Filter</h1>
        <h3 class="text-lg">{{ search.filters.geospatial.textual }}</h3>
      </template>
      <template v-slot:body>
        <div class="w-full h-96">
          <map-polygons v-model="search.filters.geospatial" :is-read-only="true"></map-polygons>
        </div>
      </template>
      <template v-slot:footer>
        <button class="btn btn-theme" href="#" @click="showGeospatialFilter = false">Close</button>
      </template>
    </modal>

    <modal v-if="filterEdit !== null">
      <template v-slot:header>
        <div class="flex w-full">
          <h1 class="text-2xl">{{ filterEdit.isAdding ? 'Add' : 'Edit' }} {{ filterEdit.display }}</h1>
        </div>
      </template>
      <template v-slot:body>
        <div class="w-full">
          <date-range v-if="filterEdit.type === 'timeRange'" v-model="filterEdit.model"></date-range>
          <div v-else-if="filterEdit.type === 'geospatial'" class="h-96">
            <map-polygons v-model="filterEdit.model" :is-read-only="false"></map-polygons>
          </div>
          <div v-else class="input-group" :title="filterEdit.display">
            <div class="input-group-text w-42px"><i class="fas fa-filter" /></div>
            <input class="form-control group-text" :type="filterEdit.model.type.toLowerCase() === 'number' ? 'number' : 'text'" v-model="filterEdit.model.value" :placeholder="`no ${filterEdit.display.toLowerCase()} filter defined`" >
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button class="btn btn-theme-muted" @click="filterEdit = null"><i class="fas fa-ban mr-1" />Cancel</button>
        <button class="btn btn-theme" @click="saveFilterEdit" :disabled="!isFilterEditValid"><i class="fas fa-save mr-1" />Save</button>
      </template>
    </modal>
  </div>
</template>

<script>
import DateRange from '@/components/DateRange'
import DisplayFormatMixin from '@/mixins/DisplayFormatMixin'
import FilesetBrowser from '@/components/FilesetBrowser'
import InlineDropdown from '@/components/InlineDropdown'
import JsonViewer from '@/components/JsonViewer'
import Loading from '@/components/Loading'
import MapComponent from '@/components/MapComponent'
import MapPolygons from '@/components/MapPolygons'
import MapSearchResults from '@/components/MapSearchResults'
import Modal from '@/components/Modal'
import RetryableError from '@/components/RetryableError'
import SearchDataMixin from '@/mixins/SearchDataMixin'
import SearchQueryMixin from '@/mixins/SearchQueryMixin'
import moment from 'moment'
import _ from 'lodash'
import Papa from 'papaparse'
import { SearchResultViewMode } from '@/utils/SearchResultViewMode.js'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'search-results',
  components: {
    DateRange,
    FilesetBrowser,
    InlineDropdown,
    JsonViewer,
    Loading,
    MapComponent,
    MapPolygons,
    MapSearchResults,
    Modal,
    RetryableError
  },
  mixins: [
    DisplayFormatMixin,
    SearchDataMixin,
    SearchQueryMixin
  ],
  data () {
    return {
      showColumnEditor: false,
      showFilters: false,
      showGeospatialFilter: false,
      locationRow: null,
      filesetRow: null,
      overflowValue: null,
      showExportModal: false,
      filterEdit: null,
      isAddingFilter: false,
      detailedCombinedTypes: ['COORDINATES', 'FILESET']
    }
  },
  computed: {
    viewModeSupportsPaging () {
      return this.search.viewMode !== SearchResultViewMode.MAP
    },
    supportsMapView () {
      if (this.search === null) return false
      if (this.search.isSample) return false
      return this.search.datasetDescription.mapSupport !== null
    },
    titleColumn () {
      if (this.search === null || this.search.datasetDescription.titleField === null) return null
      const result = _.find(this.columns, column => column.name === this.search.datasetDescription.titleField)
      if (typeof result === 'undefined') return null
      return result
    },
    subtitleColumn () {
      if (this.search === null || this.search.datasetDescription.subtitleField === null) return null
      const result = _.find(this.columns, column => column.name === this.search.datasetDescription.subtitleField)
      if (typeof result === 'undefined') return null
      return result
    },
    datasetCharacterLimit () {
      return this.$store.state.datasetCharacterLimit
    },
    curlCommand () {
      if (this.search.isSample) {
        return `curl -u ${this.$store.state.currentUser.preferred_username} ${this.$store.state.gatewayExternalHost}/datasets/${this.search.datasetDescription.category}/${this.search.datasetDescription.datasetId}/sample`
      } else {
        const postData = JSON.parse(JSON.stringify(this.searchPayload))
        if (postData.paging !== null) {
          postData.paging.offset = 0
        }
        return `curl -X POST -H 'Content-Type: application/json' -d '${JSON.stringify(postData)}' -u ${this.$store.state.currentUser.preferred_username} ${this.$store.state.gatewayExternalHost}/datasets/${this.search.datasetDescription.category}/${this.search.datasetDescription.datasetId}/search`
      }
    },
    hasMoreResults () {
      if (!this.search.datasetDescription.features.includes('PAGINATION')) return false
      if (this.search.isSample) return false
      if (this.search.results === null) return true
      if (this.search.totalResultCount === null || this.search.totalResultCount < 0) return true // we don't know the total count yet, its still loading
      return this.search.results.length < this.search.totalResultCount
    },
    actions () {
      return [
        {
          id: 'closeSearch',
          name: 'Close Search',
          icon: 'fa-times',
          performAction: () => {
            this.$swal({
              icon: 'question',
              title: 'Close Search?',
              html: `Are you sure you want to close the search that was performed on <strong>${this.search.datasetDescription.datasetId}</strong>, ${moment(this.search.timestamp).fromNow()}? <br><br>This action cannot be undone.`,
              showCancelButton: true,
              confirmButtonText: 'Close'
            }).then(result => {
              if (result.isConfirmed) {
                this.$store.commit('removeDatasetSearch', this.search.id)
                this.$router.push({ name: 'UnifiedDatasets' })
              }
            })
          }
        },
        {
          id: 'copyCurl',
          name: 'Copy cURL Command',
          icon: 'fa-copy',
          performAction: this.copyCurlCommand
        },
        {
          id: 'export',
          name: 'Export Current Results',
          icon: 'fa-download',
          disabled: this.search.results === null || this.search.results.length === 0,
          performAction: () => {
            this.showExportModal = true
          }
        },
        {
          id: 'editColumns',
          name: `${this.showColumnEditor ? 'Hide' : 'Show'} Column Visibility`,
          icon: 'fa-table',
          disabled: this.search.results === null || this.search.results.length === 0 || this.columns.length === 0 || !this.isViewModeTable,
          performAction: () => {
            this.showColumnEditor = !this.showColumnEditor
          }
        },
        {
          id: 'showFilters',
          name: `${this.showFilters ? 'Hide' : 'Show'} Filters`,
          icon: 'fa-filter',
          disabled: this.search.isSample,
          performAction: () => {
            this.showFilters = !this.showFilters
          }
        },
        {
          id: 'refresh',
          name: 'Rerun Search',
          icon: 'fa-refresh',
          disabled: this.search.isSearching,
          performAction: () => {
            this.rerunSearch()
          }
        },
        {
          id: 'saveSearch',
          name: 'Save Search',
          icon: 'fa-save',
          disabled: this.search.isSample,
          performAction: () => {
            this.saveSearch()
          }
        }
      ]
    },
    isViewModeMap () {
      if (this.search === null) return false
      return this.search.viewMode === SearchResultViewMode.MAP
    },
    isViewModeRaw () {
      if (this.search === null) return false
      return this.search.viewMode === SearchResultViewMode.RAW
    },
    isViewModeTable () {
      if (this.search === null) return false
      return this.search.viewMode === SearchResultViewMode.TABLE
    },
    filtersUsed () {
      const filters = this.generateFiltersUsed(this.search)
      for (const filter of filters) {
        if (filter.idName === 'geospatial') {
          filter.viewDetailsHandler = () => {
            this.showGeospatialFilter = true
          }
        } else {
          filter.viewDetailsHandler = null
        }
      }
      return filters
    },
    hiddenColumnCount () {
      if (this.search.visibleColumns === null) return 0
      return this.columns.length - this.search.visibleColumns.length
    },
    isFilterEditValid () {
      if (this.filterEdit === null) return false

      if (this.filterEdit.type === 'geospatial') {
        return this.filterEdit.model !== null
      } else if (this.filterEdit.type === 'timeRange') {
        return this.filterEdit.model !== null
      } else {
        return this.filterEdit.model.value !== null && this.filterEdit.model.value.toString().length > 0
      }
    },
    additionalAvailableFilters () {
      const usedFilterNames = this.search.filters.dynamic.map(filter => filter.name)
      return _.sortBy(this.search.datasetDescription.filterSupport.staticFilters.filter(filter => !usedFilterNames.includes(filter.name)), 'display')
    }
  },
  watch: {
    search () {
      this.locationRow = null
      this.filesetRow = null
      this.overflowValue = null
      this.showColumnEditor = false
      this.showFilters = false
      this.filterEdit = null
      this.isAddingFilter = false
      this.handleNewSearch()
    }
  },
  methods: {
    setViewModeMap () {
      this.setViewMode(SearchResultViewMode.MAP)
    },
    setViewModeRaw () {
      this.setViewMode(SearchResultViewMode.RAW)
    },
    setViewModeTable () {
      this.setViewMode(SearchResultViewMode.TABLE)
    },
    setViewMode (viewMode) {
      if (this.search === null) return
      this.showColumnEditor = false
      this.search.visibleColumns = null
      this.search.viewMode = viewMode
    },
    saveSearch () {
      const self = this
      this.$swal.fire({
        title: 'Save Search',
        text: 'Enter a name for the saved search.',
        input: 'text',
        inputAttributes: {
          placeholder: 'search name'
        },
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        preConfirm: async (searchName) => {
          try {
            const query = JSON.parse(JSON.stringify(self.searchPayload))
            if (typeof query.paging !== 'undefined' && query.paging !== null) {
              query.paging.offset = 0
            }
            const payload = {
              datasetCategory: self.search.datasetDescription.category,
              datasetId: self.search.datasetDescription.datasetId,
              queryName: searchName,
              query
            }
            const response = await self.$store.dispatch('saveQuery', payload)
            return response.data
          } catch (error) {
            console.log('Error saving search', error)
            const serverErrors = error?.response?.data?._embedded?.errors?.map(e => e.message) ?? []
            self.$swal.showValidationMessage(`Save failed: ${serverErrors.join('/n/n')}`)
          }
        },
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
        if (result.isConfirmed) {
          self.$store.commit('setSavedSearches', self.$store.state.savedSearches.concat([result.value]))
          self.$swal.fire({
            icon: 'success',
            title: 'Search Saved',
            text: `Search "${result.value.queryName}" succesfully saved.`
          })
        }
      })
    },
    onFilterAddChange (filterToAdd) {
      this.isAddingFilter = false
      const model = JSON.parse(JSON.stringify(filterToAdd))
      model.value = null
      model.preFilled = false
      this.filterEdit = {
        model,
        type: filterToAdd.type,
        isAdding: true,
        display: filterToAdd.display
      }
    },
    rerunSearch () {
      this.search.mapResults.items = null
      this.search.mapResults.endTimeMs = null
      this.search.results = null
      this.search.totalResultCount = null
      this.search.currentOffset = 0
      this.performSearch()
    },
    saveFilterEdit () {
      if (this.filterEdit === null) return

      if (this.filterEdit.type === 'geospatial') {
        this.search.filters.geospatial = this.filterEdit.model
      } else if (this.filterEdit.type === 'timeRange') {
        this.search.filters.timeRange = this.filterEdit.model
      } else {
        if (this.filterEdit.isAdding) {
          this.search.filters.dynamic.push(this.filterEdit.model)
        } else {
          const targetFilter = this.search.filters.dynamic.filter(f => f.name === this.filterEdit.model.name)[0]
          targetFilter.value = this.filterEdit.model.value
        }
      }

      this.filterEdit = null
      this.rerunSearch()
    },
    openFilterEditor (idName, type) {
      if (type === 'geospatial') {
        this.filterEdit = {
          type,
          isAdding: false,
          display: 'Geospatial',
          model: JSON.parse(JSON.stringify(this.search.filters.geospatial))
        }
        return
      } else if (type === 'timeRange') {
        this.filterEdit = {
          type,
          isAdding: false,
          display: 'Time Range',
          model: JSON.parse(JSON.stringify(this.search.filters.timeRange))
        }
        return
      }

      const targetFilter = this.search.filters.dynamic.filter(f => f.name === idName && f.type === type)
      if (targetFilter.length !== 1) {
        console.error(`Cannot find filter to edit with id ${idName} and type ${type}`)
        return
      }

      // for all others, use our generic editor
      this.filterEdit = {
        type,
        isAdding: false,
        display: targetFilter[0].display,
        model: JSON.parse(JSON.stringify(targetFilter[0]))
      }
    },
    async performSearch () {
      const searchId = this.search.id
      this.$store.commit('setSearchIsSearching', { searchId, isSearching: true })
      this.$store.commit('setSearchHasError', { searchId, hasError: false })
      this.$store.commit('setSearchCountError', { searchId, hasError: false })
      try {
        if (this.search.isSample) {
          const response = await this.$store.dispatch('sampleUnifiedDataset', { category: this.search.datasetDescription.category, datasetId: this.search.datasetDescription.datasetId })
          this.processResultsResponse(searchId, response)
        } else {
          // determine if the count needs fetched before awaiting the searach call, this solves a race condition if the user switches tabs while a search is ongoing
          const fetchCount = this.search.totalResultCount === null && this.search.datasetDescription.features.includes('COUNT')
          const dispatchData = { category: this.search.datasetDescription.category, datasetId: this.search.datasetDescription.datasetId, searchData: JSON.parse(JSON.stringify(this.searchPayload)) }
          const response = await this.$store.dispatch('searchUnifiedDataset', dispatchData)
          if (fetchCount) {
            this.search.totalResultCount = -1
            delete dispatchData.searchData.paging
            const self = this
            this.$store.dispatch('fetchTotalResultCount', dispatchData).then(countResponse => {
              this.$store.commit('setSearchResultCount', { searchId, resultCount: countResponse.data.totalResults })
            }).catch(error => {
              console.error('Error loading result count', error)
              self.$store.commit('setSearchResultCount', { searchId, resultCount: null })
              self.$store.commit('setSearchCountError', { searchId, hasError: true })
            })
          }
          this.processResultsResponse(searchId, response)
        }
      } catch (error) {
        console.error('Error performing search', error)
        this.$store.commit('setSearchHasError', { searchId, hasError: true })
      } finally {
        this.$store.commit('setSearchIsSearching', { searchId, isSearching: false })
      }
    },
    processResultsResponse (searchId, response) {
      let data = response.data.results
      if (typeof data === 'string') {
        // try to parse string to json
        data = JSON.parse(data)
      }

      if (typeof data === 'object') {
        if (data === null) {
          throw new Error('Unknown error from external dataset, received null.')
        }

        if (!Array.isArray(data)) {
          // if we got a single object back, wrap it as an array
          data = [data]
        }
      } else {
        throw new Error(`Unkown datatype for response, cannot parse: ${data}`)
      }

      for (const result of data) {
        result[`x${searchId}`] = uuidv4()
      }

      this.$store.commit('addResultsToSearch', { searchId, results: data })
    },
    setColumnVisibility (columnName, isVisible) {
      // initialize to all columns by default
      if (this.search.visibleColumns === null) this.search.visibleColumns = this.columns.map(c => c.name)

      if (isVisible) {
        this.search.visibleColumns.push(columnName)
      } else {
        this.search.visibleColumns = this.search.visibleColumns.filter(name => name !== columnName)
      }
    },
    showDetails (item, column) {
      if (column.combined === null) return

      if (column.combined.type === 'COORDINATES') {
        this.locationRow = { item, column }
      } else if (column.combined.type === 'FILESET') {
        this.filesetRow = { item, column }
      }
    },
    handleNewSearch () {
      if (this.search === null) {
        // if no search, kick user back to main dataset page
        this.$router.push({ name: 'UnifiedDatasets' })
      } else if (this.search.results === null && this.search.isSearching === false && this.search.searchError === false) {
        // kick off search
        this.performSearch()
      }
    },
    copyCurlCommand () {
      navigator.clipboard.writeText(this.curlCommand).then(() => {
        this.$swal({
          icon: 'success',
          title: 'cURL Command Copied!',
          timer: 2000,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false
        })
      }).catch(error => {
        this.$swal({
          icon: 'error',
          title: 'Copy Failed',
          html: `<div>Unable to copy cURL command, manually copy the command below:<br /><br /></div><div class="p-2 rounded bg-black text-white text-left">${this.curlCommand}</div>`,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        console.error('Error copying curl command', error)
      })
    },
    exportResults (format) {
      const exportResults = []
      for (const item of this.search.results) {
        const row = {}
        for (const column of this.columns) {
          row[column.display] = this.getValue(item, column, false)
        }
        exportResults.push(row)
      }

      let filename = `${this.search.datasetDescription.category}_${this.search.datasetDescription.datasetId}`
      let downloadBlob = null
      if (format === 'json') {
        filename += '.json'
        downloadBlob = new Blob([JSON.stringify(exportResults, null, 2)], { type: 'application/json' })
      } else if (format === 'csv') {
        filename += '.csv'
        downloadBlob = new Blob([Papa.unparse(exportResults)], { type: 'text/csv' })
      } else {
        console.error(`Cannot export results, unknown format: ${format}`)
        return
      }

      const url = URL.createObjectURL(downloadBlob)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      // revoke 2 seonds later to let the download finish
      setTimeout(() => {
        window.URL.revokeObjectURL(url)
      }, 2000)
    }
  },
  created () {
    this.handleNewSearch()
  }
}
</script>
