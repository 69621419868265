<template>
  <div class="flex justify-between items-center">
    <div :class="{ 'text-gray-400': currentLocation === null}">{{ name }}</div>
    <div v-if="currentLocation" class="flex gap-2 items-center">
      <loading v-if="isPathLoading" :text="`${pathLoadingPercent}%`"></loading>
      <i v-else class="fas fa-route text-theme-500 hover:text-theme-300 cursor-pointer" @click="onToggleItemPath"></i>
      <i class="fas fa-location-dot text-theme-500 hover:text-theme-300 cursor-pointer" @click="locateItem"></i>
    </div>
    <VMenu v-else :delay="{ show: 200, hide: 0 }">
      <i class="fas fa-warning text-amber-500 cursor-help" />

      <template #popper>
        <div class="p-2 max-w-72">{{ name }} was in your original search; however it is not currently on the map. Adjust the time scrubber or search criteria.</div>
      </template>
    </VMenu>
  </div>
  <div v-show="false" ref="itemPopup">
    <map-item-popup
      :title="name"
      :subtitle="subtitle"
      :location="displayFriendlyCoordinates"
      :result="displayResult"
      :header-color="stalenessColor">
    </map-item-popup>
  </div>
</template>

<script>
import { shallowRef } from 'vue'
import _ from 'lodash'
import L from 'leaflet'
import moment from 'moment'
import DisplayFormatMixin from '@/mixins/DisplayFormatMixin'
import Loading from '@/components/Loading'
import MapItemPopup from '@/components/map/MapItemPopup'
import SearchQueryMixin from '@/mixins/SearchQueryMixin'

export default {
  name: 'map-item',
  mixins: [
    DisplayFormatMixin,
    SearchQueryMixin
  ],
  components: {
    Loading,
    MapItemPopup
  },
  props: {
    name: { type: String, required: true },
    item: { type: Object, required: true },
    scrubTimeMs: { type: Number, required: false },
    map: { type: Object, required: false }
  },
  data () {
    return {
      currentLocation: null
    }
  },
  computed: {
    pathLoadingPercent () {
      return this.item.path.loadingPercent
    },
    popupInfo () {
      return `${this.name} ${this.subtitle} ${this.displayFriendlyCoordinates} ${JSON.stringify(this.displayResult)} ${this.stalenessColor}`
    },
    endTimeMs () {
      return this.search?.mapResults?.endTimeMs ?? null
    },
    isPathLoading () {
      return this.item.path.isLoading
    },
    itemMarker () {
      return this.item.mapMarker
    },
    isPathVisible () {
      return this.item.path.isVisible
    },
    pathLayer () {
      return this.item.path.layer
    },
    divIcon () {
      const faMapIcon = this.search.datasetDescription.faMapIcon
      if (faMapIcon === null) return null
      return L.divIcon({
        html: `<i class="fas ${faMapIcon} text-2xl text-${this.stalenessColor}"></i>`,
        iconSize: [27, 24],
        className: 'divIcon'
      })
    },
    stalenessColor () {
      const notStaleColor = 'theme-600'
      if (this.search.filters.timeRange === null || this.endTimeMs === null || this.timeMs === null) {
        return notStaleColor
      }

      const endTime = moment(this.endTimeMs)
      const itemTime = moment(this.timeMs)
      const hours = moment.duration(endTime.diff(itemTime)).asHours()
      if (hours < this.$store.state.mapSupport.staleWarningHours) {
        return notStaleColor
      } else if (hours < this.$store.state.mapSupport.staleErrorHours) {
        return 'amber-600'
      }
      return 'rose-600'
    },
    subtitle () {
      if (this.item.currentResult === null) return null
      const itemTemporalField = this.search.datasetDescription.mapSupport.itemTemporalField

      if (this.endTimeMs !== null && this.timeMs !== null) {
        return moment.utc(this.timeMs).from(moment(this.endTimeMs))
      } else {
        return this.getValue(this.item.currentResult, _.find(this.columns, ['name', itemTemporalField]), false)
      }
    },
    timeMs () {
      if (this.item.currentResult === null) return null
      return this.getItemTemporalMs(this.item.currentResult)
    },
    displayFriendlyCoordinates () {
      if (this.currentLocation === null) return '-'
      return `${(Math.round(this.currentLocation[0] * 100) / 100).toFixed(2)}, ${(Math.round(this.currentLocation[1] * 100) / 100).toFixed(2)}`
    },
    displayResult () {
      if (this.item.currentResult === null) return null
      const ret = {}
      for (const column of this.columns) {
        ret[column.display] = this.getValue(this.item.currentResult, column, false)
      }
      return ret
    }
  },
  watch: {
    displayResult () {
      this.setCurrentLocation()
    },
    popupInfo () {
      if (this.itemMarker === null) return
      const self = this
      this.$nextTick(() => {
        self.itemMarker.getPopup().setContent(self.$refs.itemPopup.innerHTML)
      })
    },
    map () {
      this.addMapMarker()
      this.updatePathLayerVisibility()
    },
    itemMarker () {
      this.addMapMarker()
    },
    isPathVisible () {
      this.updatePathLayerVisibility()
    },
    pathLayer () {
      this.updatePathLayerVisibility()
    },
    currentLocation () {
      // both are null, do nothing
      if (this.itemMarker === null && this.currentLocation === null) return

      if (this.itemMarker === null) {
        // we have a location but no marker, create it (creating it will end up adding it)
        this.createMapMarker()
      } else if (this.currentLocation === null) {
        // we have a marker but no location, remove it
        this.itemMarker.remove()
      } else {
        // the last possible combo is we have both, set the marker and ensure its on map
        this.itemMarker.setLatLng(this.currentLocation)
        this.addMapMarker()
      }
    },
    scrubTimeMs () {
      this.setScrubPosition()
    },
    divIcon () {
      if (this.itemMarker === null) return
      this.itemMarker.setIcon(this.divIcon)
    }
  },
  methods: {
    setCurrentLocation () {
      if (this.item.currentResult === null) {
        // if we have no path loaded or the path is empty, set current location to null
        if (this.pathLayer === null || this.pathLayer.getLatLngs().length === 0) {
          this.currentLocation = null
        } else {
          this.setScrubPosition()
        }
        return
      }

      const result = this.item.currentResult
      const itemLocationField = this.search.datasetDescription.mapSupport.itemLocationField
      const locationFields = _.find(this.search.datasetDescription.schema, ['name', itemLocationField ?? '-'])
      this.currentLocation = [result[locationFields.combined.fields[0]], result[locationFields.combined.fields[1]]]
    },
    setScrubPosition () {
      if (this.scrubTimeMs === null) return
      if (this.item.points === null) return
      if (this.itemMarker === null) return
      if (this.pathLayer === null) return

      // we have path data, use that
      const latLons = this.item.path.points.filter(p => p.timeMs < this.scrubTimeMs).map(p => p.latLon)
      this.pathLayer.setLatLngs(latLons)
      if (latLons.length > 0) {
        this.currentLocation = latLons[0]
      } else {
        this.currentLocation = null
      }
    },
    locateItem () {
      if (this.map === null || this.itemMarker === null) return
      this.map.setView(this.itemMarker.getLatLng(), 11)
      this.itemMarker.openPopup()
    },
    onToggleItemPath () {
      const searchId = this.searchId
      const shouldShow = !this.isPathVisible
      this.$store.commit('setSearchMapItemPathVisible', { searchId, itemKey: this.name, isVisible: shouldShow })
      if (shouldShow) {
        // if we are loading the path already, do nothing
        if (this.isPathLoading) return
        if (this.pathLayer === null) {
          this.fetchItemPath()
        }
      }
    },
    getItemTemporalMs (result) {
      const itemTemporalField = this.search.datasetDescription.mapSupport.itemTemporalField
      const temporalColumn = _.find(this.columns, ['name', itemTemporalField])
      if ((temporalColumn.type !== 'TIMESTAMP_SECONDS' && temporalColumn.type !== 'TIMESTAMP_MILLS')) {
        console.warn('Error getting temporatl time in Ms')
        return null
      }

      let value = result[itemTemporalField]
      if (temporalColumn.type === 'TIMESTAMP_SECONDS') {
        // this field is in seconds, convert to ms
        value = value * 1000
      }
      return value
    },
    addMapMarker () {
      if (this.map === null) return
      if (this.itemMarker === null) return
      if (this.currentLocation === null) return

      this.itemMarker.addTo(this.map)
    },
    updatePathLayerVisibility () {
      if (this.map === null) return
      if (this.pathLayer === null) return

      if (this.isPathVisible) {
        this.pathLayer.addTo(this.map)
        this.setScrubPosition()
      } else {
        this.pathLayer.remove()
      }
    },
    async fetchItemPath () {
      if (this.isPathLoading || this.pathLayer !== null) return

      const groupItemField = this.search.datasetDescription.mapSupport.groupItemField
      const itemLocationField = this.search.datasetDescription.mapSupport.itemLocationField
      const locationFields = _.find(this.search.datasetDescription.schema, ['name', itemLocationField ?? '-'])
      if (typeof locationFields === 'undefined' || groupItemField === null) {
        this.$swal({
          icon: 'error',
          title: 'Error Loading Path',
          text: 'The path of this item cannot be fetched.',
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        console.error(`Unable to find column ${itemLocationField} in schema, can't plot map`)
        return
      }

      const searchId = this.searchId
      this.$store.commit('setSearchMapItemPathLoadingPercent', { searchId, itemKey: this.name, loadingPercent: 0 })
      this.$store.commit('setSearchMapItemPathIsLoading', { searchId, itemKey: this.name, isLoading: true })
      const searchData = JSON.parse(JSON.stringify(this.searchPayload))
      const maxResults = this.$store.state.mapSupport.maxPointResults
      // TODO how to handle all path data....SSE?
      searchData.paging.offset = 0
      searchData.paging.max = maxResults

      if (typeof _.find(this.search.filters.dynamic, { name: groupItemField }) === 'undefined') {
        searchData.filters.push({
          name: groupItemField,
          operator: '=',
          value: this.name
        })
      }
      searchData.columns = [locationFields.combined.fields[0], locationFields.combined.fields[1], this.search.datasetDescription.mapSupport.itemTemporalField]
      const dispatchData = { category: this.search.datasetDescription.category, datasetId: this.search.datasetDescription.datasetId, searchData }

      try {
        const countDispatchData = JSON.parse(JSON.stringify(dispatchData))
        delete countDispatchData.searchData.paging
        const countResponse = await this.$store.dispatch('fetchTotalResultCount', countDispatchData)
        const totalPoints = countResponse.data.totalResults

        if ((totalPoints / maxResults) > this.$store.state.mapSupport.maxPathCallsNoWarning) {
          const result = await this.$swal({
            title: 'A Lot of Data',
            html: `The path data for ${this.name} is very large and may result in a long wait time or causing your browser to crash. You can try to load the path anyways. If not, you can focus your search query to a smaller result set and try again.<br /><br />Do you want to try to load this path anyways?`,
            icon: 'question',
            showCancelButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: 'Yes, Try Loading Path'
          })

          if (!result.isConfirmed) {
            return
          }
        }

        const points = []
        let pointsLoaded = 0
        let previousTimeMs = Number.MAX_SAFE_INTEGER
        while (true) {
          const response = await this.$store.dispatch('searchUnifiedDataset', dispatchData)
          const responseResultCount = response.data.results.length
          pointsLoaded += responseResultCount
          const percent = ((pointsLoaded / totalPoints) * 100).toFixed(2)
          this.$store.commit('setSearchMapItemPathLoadingPercent', { searchId, itemKey: this.name, loadingPercent: percent })
          let currentIndex = -1
          for (const result of response.data.results) {
            currentIndex += 1
            const timeMs = this.getItemTemporalMs(result)
            // ignore points less than settings apart (except for teh last item in the response - always process that)
            if (previousTimeMs - timeMs < this.$store.state.mapSupport.pathPointIntervalMs && (currentIndex + 1) !== responseResultCount) continue
            const latLon = [
              result[locationFields.combined.fields[0]],
              result[locationFields.combined.fields[1]]
            ]
            points.push({
              latLon,
              timeMs
            })

            previousTimeMs = timeMs
          }
          dispatchData.searchData.paging.offset += maxResults
          if (responseResultCount < maxResults) break
        }

        const polyLine = L.polyline(points.map(p => p.latLon), { color: '#18364e' })
        this.$store.commit('setSearchMapItemPathLayer', { searchId, itemKey: this.name, layer: shallowRef(polyLine) })
        this.$store.commit('setSearchMapItemPathPoints', { searchId, itemKey: this.name, points })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Path Loading Failed',
          text: `An error occurred while loading the path for ${this.name}. Please try again.`,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        this.$store.commit('setSearchMapItemPathLayer', { searchId, itemKey: this.name, layer: null })
        this.$store.commit('setSearchMapItemPathPoints', { searchId, itemKey: this.name, points: null })
        console.error('Error loading item path', error)
      } finally {
        this.$store.commit('setSearchMapItemPathIsLoading', { searchId, itemKey: this.name, isLoading: false })
      }
    },
    createMapMarker () {
      if (this.currentLocation === null) return
      if (this.itemMarker !== null) return

      let mapMarker = null
      if (this.divIcon === null) {
        mapMarker = L.marker(this.currentLocation)
      } else {
        mapMarker = L.marker(this.currentLocation, { icon: this.divIcon })
      }
      mapMarker.bindPopup(this.$refs.itemPopup.innerHTML)
      const searchId = this.searchId
      const itemKey = this.name
      this.$store.commit('setSearchMapItemMarker', { searchId, itemKey, mapMarker: shallowRef(mapMarker) })
    }
  },
  mounted () {
    this.setCurrentLocation()
    if (this.itemMarker === null) {
      this.createMapMarker()
    } else {
      this.addMapMarker()
    }

    this.updatePathLayerVisibility()
    this.setScrubPosition()
  },
  unmounted () {
    if (this.itemMarker !== null) {
      this.itemMarker.remove()
    }

    if (this.pathLayer !== null) {
      this.pathLayer.remove()
    }
  }
}
</script>
